@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
/*navigation*/
body{
  font-family: 'Poppins', sans-serif;
}
.navbar {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-out 0.5s;
}
.logo{
  font-family: 'Great Vibes', cursive;
  font-size: 1.5rem;
  margin: .5rem;
}
.links ul {
  margin: 0;
  padding: 0;
  display: flex;
  transition: ease-out 2s;
}
.links li a{
  padding: 1rem;
  font-size: 1.5rem;
  display: block;
  text-transform: uppercase;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  transition: ease-out 2s;
}
.links li a:hover {
  text-decoration: underline;
}
.burger-menu{
  display: none;
}
/* main section*/
.main-section{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 ;
  height: 90vh;
}
.main-text{
  max-width: 679px;
  height: 238px;
}
.main-header{
  font-size: 46px;
  width: auto;
  height: 60px;
  font-weight: bold;
  text-transform: capitalize;
}
.main-text > p{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  color: #000;    
}
.profile-links{
  display: inline-block;
  background-color: #000;
  width: 8rem;
  padding: 15px;
  margin: 9px;
  margin-left: 0;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #fff;
}
/*about section*/
.about-section{
  display: flex;
  justify-content: center;
  height: auto;
  background-color: #f2f2f2;
}
.about-container{
  width: 60vw;
}
.about-container p{
  font-size: 2vw;
  line-height: 2em;
}
.about-title{
  text-align: center;
}
/*projects section*/
.project-container{
  /*display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  */
  position: relative;
    display: grid;
    grid-template-columns: 19fr ;
    grid-template-rows: repeat(2, minmax(auto, min(75vh, var(--maxMinHeight))));
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 60px;
    max-width: 1280px;
    margin: 0px auto;
    min-height: min(100vh, var(--maxMinHeight));
}
.video-container{
  opacity: 1;
  position: sticky;
  top: 150px;
  animation: animate 1s ;
  margin-top: 80px;
}
video{
  border-radius: 7px;
  height: 100%;
  width: 100%;
}
.sub-1{
  grid-row: 1 / 2;
  max-width: 500px;
}
.sub-1 p {
  color: rgb(40, 40, 40);
  font-size: 20px;
  line-height: 30px;
}
.sub-2{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  max-width: 470px;
  z-index: 3;
  filter: drop-shadow(rgba(0, 0, 0, 0.067) 0px 1px 6px);
  grid-area: 2 / 1 / 3 / 2;
}
section{
  --maxMinHeight: 1500px;
  --gutterWidth: 60px;
  padding: 60px var(--gutterWidth);
}
.main-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  background-color: #fafafa;
}
h2{
  padding: 13px 0px;
  font-size: 2.5em;
  font-weight: 800;
  text-transform: capitalize;
}
.projects-title{
  text-align: center;
  text-transform: capitalize;
  background-color: #fafafa;
  padding: 20px 0px;
}
h3{
  text-transform: capitalize;
  margin-bottom: 10px;
  font-size: 1.5em;
}
svg{
  padding: 0px 10px;
  height: 60px;
  fill: black;
}
.project-links-container{
  padding: 15px 0;
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
}
.project-links{
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: rgb(141, 70, 227);
  text-decoration: none;
  border-radius: 15px;
  font-weight: 800;
  font-size: 18px;
}
.project-links:hover{
  text-decoration: underline;
}
.project-links svg {
  height: 20px;
  fill: #000;
  color: #fff;
}


#section-mobile{
  padding: 30px 20px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  row-gap: 100px;
}
.project-container-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.animate {
  animation: animate 1s ease-in-out;
}

/* contact section*/
.contact-section{
  height: auto;
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}
.icons-container{
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}
.icons-container img{
  height: 8vh;
  cursor: pointer;
}
@keyframes animate {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .main-section{
    display: flex;
    flex-direction: column;
    padding: 10px ;
    min-height: 85vh;
    margin: 10px 0;
    gap: 40px;
  }
  .main-text{
    padding: 20px 10px;
    min-height: 350px;
  }
  .main-header {
    height: auto;
  }
  .burger-menu{
    display: block;
    cursor: pointer;
    transition: 2s;
  }
  .lines.open:nth-child(1){
    transform: rotate(45deg) translate(1px, 1px);
    transition: ease-out 0.5s;
  }
  .lines.open:nth-child(2){
    transform: rotate(-45deg) translate(3px, -4px);
    transition: ease-out 0.5s;
  }
  .lines.open:nth-child(3){
    display: none;
    transition: ease-out 0.5s;
  }
  .lines{
    display: block;
    margin: 3px;
    height: 4px;
    width: 30px;
    background-color:  rgb(97, 90, 90);
    border-radius: 2px;
    transition: ease-out 0.5s;
  }
  .links{
    display: none;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    transition: ease-out 2s;
  }
  .links ul {
    display: block;
    width: fit-content;
    margin: 80px auto 0 auto;
    text-align: center;
  }
  .links ul li a{
    margin-bottom: 12px;
  }
  .about-section{
    width: 100%;
    padding: 40px;
  }
  .about-container{
    width: 90%;
  }
  .about-container p{
    font-size: 5vw;
    line-height: 2.5em;
  }
  .contact-section{
    padding: 20px 0px;
  }
  .projects-title{
    background-color: #fff;
  }
}